import AppRoute from "./routes/appRoutes";


function App() {
  return (
    <>
      <AppRoute />
    </>
  );
}

export default App;